<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12">
        <v-card id="card-custom" class="px-2 py-4 rounded-lg">
          <v-card-actions>
            <p class="headline-color px-2">Penjadwalan Unit</p>
          </v-card-actions>
          <v-divider />
          <v-row class="mx-2" id="card-custom">
            <v-col cols="12" xl="9" lg="9" md="9" sm="9" xs="12"> </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="12">
              <v-text-field
                v-model="filter.search"
                placeholder="Cari"
                outlined
                dense
                hide-details
                prepend-inner-icon="search"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row id="card-custom">
            <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12">
              <!-- <v-btn
                text
                class="text-capitalize caption headline-color"
                color="secondary"
                @click="isArchive = !isArchive"
                >{{ isArchive ? "Kembali" : "Lihat Arsip"
                }}<v-icon class="ml-2" color="secondary">{{
                  isArchive ? "list" : "archive"
                }}</v-icon></v-btn
              > -->
              <v-btn
                text
                class="text-capitalize caption headline-color"
                color="primary"
                @click="
                  form.id = null;
                  visible = true;
                "
                >Tambah Unit Jadwal
                <v-icon class="ml-2" color="primary">add_circle</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12"> </v-col>
          </v-row>
          <v-divider />
          <v-data-table
            id="table-custom"
            :headers="headers"
            :items="items"
            :loading="loading"
            :options.sync="options"
            :server-items-length="pagination.totalItem"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems
            }"
            loading-text="Loading... Please wait"
            item-key="no"
          >
            <template v-slot:[`item.types`]="{ item }">
              {{ item.types }}
              <v-tooltip bottom v-if="item.default == 1">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small color="success">check_circle</v-icon>
                </template>
                <span>Default</span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.default_wfh == 1">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small color="success">check_circle</v-icon>
                </template>
                <span>Default WFH</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.default`]="{ item }">
              <v-btn
                v-if="
                  item.default == 0 &&
                    item.id != 0 &&
                    (currentUser.role == 'ADMIN' ||
                      currentUser.role == 'ADMIN_PUSAT')
                "
                x-small
                color="#297BBF"
                class="mr-2 my-1 white--text"
                @click="handleDefault(item)"
                >Set Default</v-btn
              >
              <v-btn
                v-if="
                  item.btn_wfh &&
                    item.id != 0 &&
                    (currentUser.role == 'ADMIN' ||
                      currentUser.role == 'ADMIN_PUSAT')
                "
                x-small
                color="#297BBF"
                class="mr-2 my-1 white--text"
                @click="handleDefault(item, true)"
                >Set Default WFH</v-btn
              >
            </template>
            <template v-slot:[`item.name`]="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:[`item.check`]="{ item }">
              {{ item.check_in }} - {{ item.check_out }}
            </template>
            <template v-slot:[`item.unit`]="{ item }">
              <div class="py-1">
                <p class="mb-0">
                  <b>Unit Utama: </b>{{ item.nama_unit_utama }}
                </p>
                <p class="mb-0">
                  <b>Unit Kerja Group: </b>{{ item.nama_uk_group }}
                </p>
                <p class="mb-0">
                  <b>Unit Kerja: </b>{{ item.nama_unit_kerja_2 }}
                </p>
              </div>
            </template>
            <template v-slot:[`item.max_check_in`]="{ item }">
              {{ item.max_check_in }} Menit
            </template>
            <template v-slot:[`item.status`]="{ item }">
              {{ item.status ? "ACTIVE" : "INACTIVE" }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                v-if="item.id != 0"
                x-small
                color="#297BBF"
                class="mr-2 my-1 white--text"
                @click="editUnitJadwal(item)"
                >Edit</v-btn
              >
              <v-btn
                v-if="item.id != 0"
                x-small
                :color="item.status ? '#FBB005' : '#4BAF51'"
                class="mr-2 my-1 white--text"
                @click="handleActivate(item)"
                >{{ item.status ? "Inactivate" : "Activate" }}</v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="visible" persistent max-width="600">
      <v-card class="pa-5 rounded-lg" id="card-custom">
        <v-btn @click="visible = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          Buat Unit Jadwal
        </v-card-text>
        <v-divider class="mb-4" />
        <v-form
          id="form"
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-btn-toggle
            v-model="form.types"
            class="ml-3 mb-3"
            id="btn-type"
            mandatory
          >
            <v-btn small color="#1792E6" class="px-8 py-2">
              <span class="text-capitalize font-weight-regular">WFO</span>
            </v-btn>
            <v-btn small color="#1792E6" class="px-8 py-2">
              <span class="text-capitalize font-weight-regular">WFH</span>
            </v-btn>
          </v-btn-toggle>
          <v-row>
            <v-col cols="5" class="py-0">
              <v-subheader class="caption headline-color font-weight-regular"
                >Nama Unit</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-0">
              <v-text-field
                v-model="form.name"
                :rules="[v => !!v || 'Nama harus diisi']"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-0">
              <v-subheader class="caption headline-color font-weight-regular"
                >Jam Masuk</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-0">
              <v-text-field
                v-model="form.check_in"
                :rules="[v => !!v || 'Jam masuk harus diisi']"
                outlined
                dense
                type="time"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-0">
              <v-subheader class="caption headline-color font-weight-regular"
                >Jam Pulang</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-0">
              <v-text-field
                v-model="form.check_out"
                :rules="[v => !!v || 'Jam pulang harus diisi']"
                outlined
                dense
                type="time"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-0">
              <v-subheader class="caption headline-color font-weight-regular"
                >Jumat</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-0">
              <v-card-actions class="pa-0">
                <v-checkbox
                  v-model="form.jumatcheck"
                  class="mr-2 mt-0"
                ></v-checkbox>
                <v-text-field
                  v-model="form.jumattime"
                  :rules="[
                    v =>
                      !form.jumatcheck ||
                      (form.jumatcheck && !!v) ||
                      'Waktu jumat harus diisi'
                  ]"
                  outlined
                  dense
                  :disabled="!form.jumatcheck"
                  type="number"
                  suffix="Menit"
                ></v-text-field>
              </v-card-actions>
            </v-col>
          </v-row>
          <v-row v-if="form.id">
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Aktif</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-switch v-model="form.status"></v-switch>
            </v-col>
          </v-row>
          <v-card-actions class="mt-3">
            <v-spacer />
            <v-btn
              small
              outlined
              color="#717171"
              class="px-12"
              @click="visible = false"
              >Batal</v-btn
            >
            <v-btn
              small
              color="#FBB005"
              class="px-12 subtitle-2 text-capitalize"
              :loading="formLoading"
              type="submit"
              :disabled="!valid"
              form="form"
              >{{ this.form.id ? "Ubah" : "Buat" }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ScheduleUnitService from "@/services/resources/schedule-unit.service";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      visible: false,
      valid: false,
      loading: true,
      loadingUnit: false,
      formLoading: false,
      isArchive: false,
      type: "ACTIVE",
      filter: {
        search: null,
        unit_utama_id: null,
        uk_group_id: null,
        unit_kerja_2_id: null
      },
      unitList: [],
      unitList1: [],
      unitList2: [],
      units: [],
      units1: [],
      units2: [],
      form: {
        id: null,
        name: null,
        types: 0,
        check_in: null,
        max_check_in: null,
        check_out: null,
        default: null,
        status: null,
        jumatcheck: false,
        jumattime: null
      },
      // DataTable
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItem: 0,
        pageCount: 0
      },
      options: {
        sortBy: ["name"],
        sortDesc: [false]
      },
      rowsPerPageItems: [10, 20, 30, 40],
      headers: [
        { text: "No", value: "no", sortable: false },
        { text: "TIPE", value: "types", sortable: false },
        { text: "DEFAULT", value: "default", sortable: false },
        { text: "NAMA UNIT", value: "name", sortable: false },
        { text: "JAM", value: "check", sortable: false },
        { text: "STATUS", value: "status", sortable: false },
        { text: "", value: "actions", sortable: false }
      ],
      items: []
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser"
    }),
    paginationProperty() {
      return [
        this.filter.search,
        this.filter.unit_utama_id,
        this.filter.uk_group_id,
        this.filter.unit_kerja_2_id
      ].join();
    }
  },
  watch: {
    isArchive(val) {
      if (val) {
        this.type = "INACTIVE";
      } else {
        this.type = "ACTIVE";
      }
    },
    options: {
      handler() {
        this.fetchListDebounce(this.getListSchedule);
      },
      deep: true
    },
    visible(val) {
      if (!val) {
        this.$refs.form.reset();
      }
    },
    paginationProperty: function() {
      this.options.page = 1;
      this.fetchListDebounce(this.getListSchedule);
    }
  },
  methods: {
    changeUnit(type = 0) {
      if (type == 0) {
        this.units1 = this.units.find(
          d => d.unit_utama_id == this.form.unit_utama_id
        )?.unit_kerja_group;
        this.form.uk_group_id = null;
        this.form.unit_kerja_2_id = null;
      } else if (type == 1) {
        this.units2 = this.units1.find(
          d => d.uk_group_id == this.form.uk_group_id
        )?.unit_kerja2;
        this.form.unit_kerja_2_id = null;
      }
    },
    changeUnitList(type = 0) {
      if (type == 0) {
        this.unitList1 = this.unitList.find(
          d => d.unit_utama_id == this.filter.unit_utama_id
        )?.unit_kerja_group;
        this.filter.uk_group_id = null;
        this.filter.unit_kerja_2_id = null;
      } else if (type == 1) {
        this.unitList2 = this.unitList1.find(
          d => d.uk_group_id == this.filter.uk_group_id
        )?.unit_kerja2;
        this.filter.unit_kerja_2_id = null;
      }
    },
    populateUnit() {
      this.units1 = this.units.find(
        d => d.unit_utama_id == this.form.unit_utama_id
      )?.unit_kerja_group;
      this.units2 = this.units1.find(
        d => d.uk_group_id == this.form.uk_group_id
      )?.unit_kerja2;
    },
    handleDefault(item, wfh = false) {
      this.$confirm({
        title: "Ubah Unit Jadwal",
        message: `Apakah Anda yakin akan mengubah unit jadwal ini menjadi default?`,
        button: {
          no: "Batal",
          yes: "Ubah"
        },
        callback: confirm => {
          if (confirm) {
            if (wfh) {
              this.setDefaultScheduleWFH({
                id: item.id
              });
            } else {
              this.setDefaultSchedule({
                id: item.id
              });
            }
          }
        }
      });
    },
    handleActivate(item) {
      this.$confirm({
        title: `${item.status ? "Inactivate" : "Activate"} Unit Jadwal`,
        message: `Apakah Anda yakin akan ${
          item.status ? "menonaktifkan" : "mengaktifkan"
        } unit jadwal ini?`,
        button: {
          no: "Batal",
          yes: item.status ? "Inactivate" : "Activate"
        },
        callback: confirm => {
          if (confirm) {
            this.saveSchedule({
              ...item,
              status: !item.status,
              action: 2
            });
          }
        }
      });
    },
    editUnitJadwal(item) {
      this.visible = true;
      this.form = {
        id: item.id,
        name: item.name,
        types: item.types == "WFO" ? 0 : 1,
        check_in: item.check_in,
        max_check_in: item.max_check_in,
        check_out: item.check_out,
        max_check_out: item.max_check_out,
        unit_utama_id: item.unit_utama_id,
        uk_group_id: item.uk_group_id,
        unit_kerja_2_id: item.unit_kerja_2_id,
        default: item.default == "Y" ? true : false,
        status: item.status,
        jumatcheck: item.jumatcheck,
        jumattime: item.jumattime
      };
      // this.getDetailSchedule(item.id);
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.form.id) {
          let payload = {
            action: 2,
            id: this.form.id,
            types: this.form.types == 1 ? "WFH" : "WFO",
            name: this.form.name,
            check_in: this.form.check_in,
            max_check_in: this.form.max_check_in,
            check_out: this.form.check_out,
            default: this.form.default ? "Y" : "N",
            status: this.form.status,
            jumatcheck: this.form.jumatcheck || false,
            jumattime: this.form.jumattime
          };
          this.saveSchedule(payload);
        } else {
          let payload = {
            action: 1,
            id: 0,
            types: this.form.types == 1 ? "WFH" : "WFO",
            name: this.form.name,
            check_in: this.form.check_in,
            max_check_in: this.form.max_check_in,
            check_out: this.form.check_out,
            default: this.form.default ? "Y" : "N",
            status: 1,
            jumatcheck: this.form.jumatcheck,
            jumattime: this.form.jumattime
          };
          this.saveSchedule(payload);
        }
      }
    },
    async getListSchedule() {
      try {
        const { page, itemsPerPage } = this.options;
        this.loading = true;
        await ScheduleUnitService.getList({
          filter: {
            search: this.filter.search,
            unit_utama_id: this.filter.unit_utama_id,
            uk_group_id: this.filter.uk_group_id,
            unit_kerja_2_id: this.filter.unit_kerja_2_id
          },
          orderBy: ["id", "asc"],
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            const { list, pageTotal, countTotal } = data;
            if (status) {
              let items = list;
              items.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                return data;
              });

              this.items = items;
              this.pagination.totalItem = parseInt(countTotal);
              this.pagination.pageCount = parseInt(pageTotal);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getDetailSchedule(id) {
      try {
        this.loading = true;
        await ScheduleUnitService.getDetail(id)
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.form = {
                id: data.id,
                name: data.name,
                types: data.types == "WFO" ? 0 : 1,
                check_in: data.check_in,
                max_check_in: data.max_check_in,
                check_out: data.check_out,
                max_check_out: data.max_check_out,
                unit_utama_id: data.unit_utama_id,
                uk_group_id: data.uk_group_id,
                unit_kerja_2_id: data.unit_kerja_2_id,
                default: data.default == "Y" ? true : false,
                status: data.status == "ACTIVE" ? true : false,
                jumatcheck: data.jumatcheck,
                jumattime: data.jumattime
              };
              this.populateUnit();
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async saveSchedule(data) {
      try {
        this.formLoading = true;
        await ScheduleUnitService.save(data)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getListSchedule();
              this.visible = false;
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async setDefaultSchedule(data) {
      try {
        this.formLoading = true;
        await ScheduleUnitService.setDefault(data)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.selected = [];
              this.getListSchedule();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async setDefaultScheduleWFH(data) {
      try {
        this.formLoading = true;
        await ScheduleUnitService.setDefaultWFH(data)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.selected = [];
              this.getListSchedule();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async deleteSchedule(id, type = "delete") {
      try {
        this.formLoading = true;
        await ScheduleUnitService[type](id)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.selected = [];
              this.getListSchedule();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getListSchedule();
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: 0.775rem;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.675rem;
}
#btn-type .v-btn--contained {
  background-color: #ffffff !important;
  .v-btn__content {
    color: #717171 !important;
  }
}
#btn-type .v-btn--active {
  background-color: #1792e6 !important;
  .v-btn__content {
    color: #ffffff !important;
  }
}
#custom-pagination {
  .v-pagination__navigation {
    min-width: 24px;
    width: 24px;
    height: 24px;
    .v-icon {
      font-size: 14px;
    }
  }
  .v-pagination__item {
    font-size: 0.6rem;
    min-width: 24px;
    width: 24px;
    height: 24px;
  }
}
</style>
